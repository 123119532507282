import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styles/media';

const CTA = styled.a`
  display: block;
  width: 100%;
  font-family: 'Libre Baskerville', serif;
  font-style: italic;
  text-align: center;
  font-weight: 400;
  max-width: 180px;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 50px;
  background: #ff6c00;
  margin: 0 auto;
  transition: background 300ms;
  @media ${media.small} {
    max-width: 200px;
    font-size: 1.15rem;
    &:hover {
      cursor: pointer;
      background: #fd8b1c;
    }
  }
  @media ${media.medium} {
    max-width: 250px;
    font-size: 1.75rem;
  }
`;

const CTAButton = ({ ctaHref, ctaText, size }) => (
  <CTA href={ctaHref} className={size}>
    {ctaText}
  </CTA>
);

CTAButton.propTypes = {
  size: PropTypes.string,
  ctaHref: PropTypes.string,
  ctaText: PropTypes.string,
};

export default CTAButton;
