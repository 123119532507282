import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Heading from 'components/Heading';
import { Wrapper, Content } from './styles';

const InTheBook = () => {
  const [on, setOn] = useState(false);

  const fadeIn = useSpring({
    from: { opacity: 0, transform: `translate3d(-10%,0,0)` },
    opacity: on ? 1 : 0,
    transform: on ? `translate3d(0,0,0)` : `translate3d(-10%,0,0)`,
  });
  return (
    <StaticQuery
      query={graphql`
        query INTRO_QUERY {
          bookMockup: file(relativePath: { eq: "global/book-mock.png" }) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Wrapper>
          <Waypoint bottomOffset="35%" onEnter={() => setOn(true)} />
          <Heading text="Inside The Book" />
          <Content>
            <div>
              <animated.p style={fadeIn} className="text">
                Over the span of fifteen years I have observed pets and their
                owners through the eyes of a groomer and as a grooming shop
                owner. The following pages are an accumulation of funny,
                inspirational, insightful and sometimes heart stopping moments
                that I have experienced as a groomer plus some tips that have
                helped me to reach the goal of owning and operating a successful
                grooming shop.
              </animated.p>
              <animated.p style={fadeIn} className="text">
                If you are a pet owner, I hope to make you aware of what you
                should look for and what should signal a red flag when leaving
                your pet at a grooming shop or in the hands of a Mobile Groomer.
                If you are a groomer, grooming shop owner or aspire to one day
                own a grooming shop, I hope the lessons I have learned will
                enlighten as well as inspire you to be the best at what you do;
                keeping pets safe and happy while making them beautiful.
              </animated.p>
            </div>
            <animated.div style={fadeIn}>
              <Img
                fluid={data.bookMockup.childImageSharp.fluid}
                alt="Mockup of the Gentle Groomin Book"
                style={{ maxWidth: 400, margin: '0 auto' }}
              />
            </animated.div>
          </Content>
        </Wrapper>
      )}
    />
  );
};

export default InTheBook;
