import styled from 'styled-components';
import media from 'styles/media';
import PawIcon from 'images/icons/icon-paw.svg';

export const Wrapper = styled.section`
  display: block;
  .content {
    @media ${media.small} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
    @media ${media.medium} {
      grid-template-columns: repeat(3, 1fr);
    }
    // IE11 targeted media query
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      flex-flow: row wrap;
      p {
        flex: 40%;
        margin: 0.5rem 1rem 0.5rem 0;
      }
    }
  }
  p {
    position: relative;
    padding-left: 30px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &::before {
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      content: url(${PawIcon});
    }
  }
`;
