import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  display: block;
`;

export const Content = styled.div`
  @media ${media.small} {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  // IE11 targeted media query
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    div {
      &:first-of-type {
        flex: 70%;
      }
      &:last-of-type {
        flex: 30%;
      }
    }
  }
`;
