import styled from 'styled-components';
import media from 'styles/media';
import fence from 'images/global/fence.svg';
import fenceIE from 'images/global/fence.png';
import tree from 'images/backgrounds/tree.svg';

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 0;
  div.bg-image {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const Message = styled.div`
  order: 1;
  z-index: 555;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0 2rem 0;
  @media ${media.medium} {
    order: 2;
  }
  // IE11 targeted media query
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    order: 1 !important;
  }
  h2 {
    font-size: 2.5rem;
    color: #005388;
    @media ${media.small} {
      font-size: 3.5rem;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  position: relative;
  z-index: 333;
  bottom: -31px;
  width: 100%;
  max-width: 1170px;
  height: 100%;
  margin: 0 auto;
  @media ${media.medium} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'puppy message';
  }
  // IE11 targeted media query
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
  }
`;

export const Fence = styled.div`
  position: relative;
  z-index: 555;
  width: 100%;
  min-width: 100%;
  height: 200px;
  padding: 0;
  margin: 0 auto;
  background: url(${fence}) repeat-x left top;
  background-size: contain;
  @media ${media.xsmall} {
    height: 250px;
  }
  @media ${media.small} {
    height: 350px;
  }
  // IE11 targeted media query
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: url(${fenceIE}) repeat-x left top;
  }
`;

export const Tree = styled.div`
  position: absolute;
  width: 650px;
  height: 550px;
  bottom: -5%;
  left: -10%;
  background: url(${tree}) no-repeat center bottom;
  background-size: contain;
`;
