import React from 'react';

// Import components
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import Layout from 'components/Layout';
import Prompt from 'components/Prompt';
import Intro from 'components/Sections/Intro';
import Background from 'components/Background';
import Topics from 'components/Sections/Topics';
import CTAPuppyThought from 'components/Sections/CTA/CTAPuppyThought';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Background bgType="clouds">
      <Hero />
      <Prompt />
      <Intro />
      <Topics />
      <CTAPuppyThought />
    </Background>
  </Layout>
);

export default IndexPage;
