import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import CTAButton from 'components/Elements/CTAButton';
import { Wrapper, HeroBg } from './styles';

const HERO_BG_QUERY = graphql`
  query {
    mobile: file(relativePath: { eq: "hero/dog-bath-sm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laptop: file(relativePath: { eq: "hero/dog-bath-lg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktop: file(relativePath: { eq: "hero/dog-bath-xxl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Hero = () => (
  <StaticQuery
    query={HERO_BG_QUERY}
    render={data => (
      <Wrapper>
        <div className="content-wrap">
          <div className="content">
            <header>
              <h1>
                Gentle
                <br />
                Grooming
              </h1>
              <h4>Tips &amp; Tales From a Successful Grooming Shop Owner</h4>
              <p className="cite-author">Written By: Cheri McCoy</p>
            </header>
            <CTAButton
              ctaText="Buy Now!"
              ctaHref="https://www.amazon.com/dp/108702160X?ref_=pe_3052080_397514860"
            />
          </div>
        </div>
        <HeroBg>
          <div className="hero-background mobile">
            <Img fluid={data.mobile.childImageSharp.fluid} alt="" />
          </div>
          <div className="hero-background laptop">
            <Img fluid={data.laptop.childImageSharp.fluid} alt="" />
          </div>
          <div className="hero-background desktop">
            <Img fluid={data.desktop.childImageSharp.fluid} alt="" />
          </div>
        </HeroBg>
      </Wrapper>
    )}
  />
);

export default Hero;
