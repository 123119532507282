import React from 'react';
import CTAButton from 'components/Elements/CTAButton';
import PuppyAnimation from './PuppyAnimation';
import {
  Wrapper, Content, Message, Fence, Tree,
} from './styles';

const CTAPuppyThought = () => (
  <Wrapper>
    <Content>
      <Tree />
      <PuppyAnimation />
      <Message>
        <h2>Get Your Copy Today!</h2>
        <CTAButton
          ctaText="Buy Now!"
          ctaHref="https://www.amazon.com/dp/108702160X?ref_=pe_3052080_397514860"
        />
      </Message>
    </Content>
    <Fence />
  </Wrapper>
);

export default CTAPuppyThought;
