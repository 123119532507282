import React, { useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { IoMdArrowDown } from 'react-icons/io';

const Wrapper = styled(animated.div)`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005388;
  svg {
    fill: white;
  }
  p {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.875rem;
    margin: 0;
    padding: 0;
    color: white;
  }
`;

const Prompt = () => {
  const [on, setOn] = useState(false);

  // Check for page scroll and hide scroll prompt if true
  function checkIsScrolled() {
    if (window.scrollY > 10) {
      return setOn(true);
    }
  }
  useEffect(() => {
    checkIsScrolled();
  });

  const fadeOut = useSpring({
    from: { opacity: 1 },
    opacity: on ? 0 : 1,
  });

  return (
    <Wrapper style={fadeOut}>
      <Waypoint
        topOffset="90%"
        onLeave={() => setOn(true)}
        onEnter={() => setOn(false)}
      />
      <IoMdArrowDown />
      <p>Learn More</p>
      <IoMdArrowDown />
    </Wrapper>
  );
};

export default Prompt;
