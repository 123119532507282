import styled from 'styled-components';
import media from 'styles/media';
import layout from 'styles/layout';

export const Wrapper = styled.div`
  position: relative;
  z-index: 9999;
  display: block;
  width: 100%;
  height: 75vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  @media ${media.small} {
    height: 825px;
  }
  @media ${media.medium} {
    height: 700px;
  }
  @media ${media.large} {
    height: 85vh;
  }
  // Content Wrapper
  .content-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 9999;
    width: 100%;
    max-width: ${layout.maxWidth};
    height: 75vh;
    padding: 0 2rem;
    margin: 0 auto;
    @media ${media.small} {
      height: 825px;
      padding: 0 3rem;
    }
    @media ${media.medium} {
      justify-content: flex-start;
      height: 700px;
    }
    @media ${media.large} {
      height: 80vh;
      min-height: 800px;
      padding: 0 8rem;
    }
    @media ${media.xxlarge} {
      padding: 0 5rem;
    }
    .content {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 4.5vh 0 2rem 0;
      @media ${media.small} {
        height: 825px;
        padding: 4rem 0;
      }
      @media ${media.medium} {
        justify-content: center;
        height: 700px;
        padding: 0;
      }
      @media ${media.large} {
        /* min-height: 800px; */
        height: 80vh;
        padding: 2rem 0;
      }
    }
    // CTA Button
    a {
      @media ${media.small} {
        display: block;
      }
    }
  }
  // Header
  header {
    h1 {
      display: inline-block;
      color: #fefefe;
      font-weight: 600;
      letter-spacing: -0.1rem;
      text-align: center;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
      font-size: 7.25vh;
      line-height: 0.875;
      margin: 0;
      transition: font-size 300ms;
      @media ${media.small} {
        font-size: 6.35rem;
      }
      @media ${media.large} {
        font-size: 7rem;
      }
      @media ${media.xlarge} {
        /* font-size: 6.8vw; */
        font-size: 7.5rem;
      }
      @media ${media.xxlarge} {
        font-size: 5vw;
      }
    }
    h4 {
      color: #fefefe;
      display: block;
      font-weight: 400;
      /* font-size: 3.4vw; */
      font-size: 0.85rem;
      max-width: 250px;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
      margin: 0.8rem auto 0;

      @media ${media.xsmall} {
        font-size: 1.25rem;
        margin-top: 1rem;
        max-width: 350px;
      }

      @media ${media.small} {
        margin: 1.5rem auto 0;
        max-width: initial;
        font-size: 1.2rem;
      }
      @media ${media.large} {
        font-size: 1.875;
      }
      @media ${media.xxlarge} {
        font-size: 1.5rem;
      }
    }
  }
  p {
    // Cite Author
    &.cite-author {
      display: inline-block;
      color: #fefefe;
      font-size: 0.675rem;
      font-weight: bold;
      padding: 0.25rem 0 0.5rem;
      border-top: 1px dotted #fefefe;
      margin-top: 0.55rem;
    }
    // Blurb
    &.blurb {
      display: none;
      @media ${media.medium} {
        display: block;
        max-width: 500px;
        text-align: center;
        color: #fefefe;
        line-height: 1.25;
        font-size: 1.15rem;
        padding: 1rem 0;
        font-style: italic;
        font-weight: 300;
        margin: 0 auto 2.25rem;
        border-top: 10px solid #fefefe;
      }
    }
  }
  // Learn More Prompt Button
  .learn-more {
    position: absolute;
    bottom: -10px;
    @media ${media.medium} {
      display: none;
    }
    p {
      color: #000;
    }
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: -50px;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  .hero-background {
    height: 125%;
    > div {
      height: 100%;
    }
    position: static !important;
    transition: height 300ms ease-in-out;
    img {
      object-position: 80% top;
    }
    &.mobile {
      display: block;
      img {
        object-position: top center !important;
      }
      @media ${media.medium} {
        display: none;
      }
    }
    &.laptop {
      display: none;
      @media ${media.medium} {
        display: block;
        object-position: 45% top;
      }
      @media ${media.large} {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @media ${media.large} {
        display: block;
        object-position: 50% top;
      }
    }
  }
`;
