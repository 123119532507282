import React, { useState } from 'react';
import { useTrail, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Heading from 'components/Heading';
import { Wrapper } from './styles';

const Topics = () => {
  const [on, setOn] = useState(false);

  const items = [
    `First-hand accounts of my years as an owner of a successful grooming shop.`,
    `How I handled client organization and appointments.`,
    `How to handle the “Winter Slowdown”.`,
    `How I got my start in grooming and how I turned my passion into a thriving business.`,
    `Grass roots advertising for your storefront.`,
    `Lots of tips! I share my insights through my years of experience with you throughout the book.`,
  ];

  const fadeInTrail = useTrail(items.length, {
    from: { transform: `translate3d(-10%,0,0)`, opacity: 0 },
    opacity: on ? 1 : 0,
    transform: on ? `translate3d(0,0,0)` : `translate3d(-10%,0,0)`,
  });

  return (
    <Wrapper>
      <Waypoint bottomOffset="35%" onEnter={() => setOn(true)} />
      <Heading text="Topics Covered" />
      <div className="content">
        {fadeInTrail.map((props, index) => (
          <animated.p style={props} key={items[index]}>
            {items[index]}
          </animated.p>
        ))}
      </div>
    </Wrapper>
  );
};

export default Topics;
