import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, useChain, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import { easeCubicOut } from 'd3-ease';

// Import styles
import media from 'styles/media';

// Import images
import puppyPeeking from 'images/global/puppy-peeking.png';
import tBubble0 from 'images/global/t-bubble-0.png';
import tBubble1 from 'images/global/t-bubble-1.png';
import tBubble2 from 'images/global/t-bubble-2.png';
import tBubble3 from 'images/global/t-bubble-3.png';
import tBubble4 from 'images/global/t-bubble-4.png';

// Styled components
const Wrapper = styled.div`
  order: 2;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  @media ${media.medium} {
    order: 1;
  }
  // IE11 targeted media query
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    order: 1 !important;
  }
  img.puppy-peeking {
    position: relative;
    display: block;
    bottom: 0;
    left: 35px;
    width: 100%;
    max-width: 325px;
    transform: translate(0, 0);
    margin: 0;
    display: block;
    object-fit: contain !important;
    @media ${media.small} {
      left: 79px;
      max-width: 480px;
      margin: 0 0 -15px 0;
    }
    @media ${media.medium} {
      left: 40px;
    }
  }
  div.thought-bubble {
    position: relative;
    width: 100%;
    .t-bubble {
      position: relative;
      display: block;
      line-height: 1;
      img {
        margin: 0;
        width: 100%;
      }
      &.tb0 {
        padding-left: 20%;
        padding-right: 6%;
        transform: translate(0, 30px);
        @media ${media.small} {
          padding-left: 33%;
          padding-right: 1%;
        }
        img {
          max-width: 380px;
        }
      }
      &.tb1 {
        padding-left: 56%;
        padding-right: 25%;
        @media ${media.small} {
          padding-left: 65%;
          padding-right: 20%;
        }
        img {
          max-width: 82px;
        }
      }
      &.tb2 {
        padding-left: 57%;
        padding-right: 32%;
        @media ${media.small} {
          padding-left: 65%;
          padding-right: 26%;
        }
        img {
          max-width: 45px;
        }
      }
      &.tb3 {
        padding-left: 53%;
        padding-right: 38%;
        @media ${media.small} {
          padding-left: 62%;
          padding-right: 31%;
        }
        img {
          max-width: 35px;
        }
      }
      &.tb4 {
        padding-left: 49%;
        padding-right: 45%;
        @media ${media.small} {
          padding-left: 60%;
          padding-right: 35%;
        }
        img {
          max-width: 25px;
        }
      }
    }
  }
`;

const PuppyThoughtBubble = () => {
  const [on, setOn] = useState(false);

  const bubble0Ref = useRef();
  const bubble0 = useSpring({
    ref: bubble0Ref,
    from: { opacity: 0 },
    opacity: 1,
    config: {
      duration: 1500,
      easing: easeCubicOut,
    },
  });
  const bubble1Ref = useRef();
  const bubble1 = useSpring({
    ref: bubble1Ref,
    from: { transform: `translate3d(0, 20%, 0)`, opacity: 0 },
    transform: `translate3d(0, 0, 0)`,
    opacity: 1,
    config: {
      duration: 200,
      easing: easeCubicOut,
    },
  });
  const bubble2Ref = useRef();
  const bubble2 = useSpring({
    ref: bubble2Ref,
    from: { transform: `translate3d(0, 20%, 0)`, opacity: 0 },
    transform: `translate3d(0, 0, 0)`,
    opacity: 1,
    config: {
      duration: 100,
      easing: easeCubicOut,
    },
  });
  const bubble3Ref = useRef();
  const bubble3 = useSpring({
    ref: bubble3Ref,
    from: { transform: `translate3d(0, 20%, 0)`, opacity: 0 },
    transform: `translate3d(0, 0, 0)`,
    opacity: 1,
    config: {
      duration: 50,
      easing: easeCubicOut,
    },
  });
  const bubble4Ref = useRef();
  const bubble4 = useSpring({
    ref: bubble4Ref,
    from: { transform: `translate3d(0, 20%, 0)`, opacity: 0 },
    transform: `translate3d(0, 0, 0)`,
    opacity: 1,
    config: {
      duration: 50,
      easing: easeCubicOut,
    },
  });
  const puppyRef = useRef();
  const puppyPeek = useSpring({
    ref: puppyRef,
    from: { transform: `translate3d(0, 70%, 0)` },
    transform: `translate3d(0, 0, 0)`,
  });

  useChain(
    on
      ? [puppyRef, bubble4Ref, bubble3Ref, bubble2Ref, bubble1Ref, bubble0Ref]
      : [],
  );

  return (
    <Wrapper>
      <div className="thought-bubble">
        <animated.div style={bubble0} className="t-bubble tb0">
          <img src={tBubble0} alt="Thought Bubble" />
        </animated.div>
        <animated.div style={bubble1} className="t-bubble tb1">
          <img src={tBubble1} alt="Thought Bubble" />
        </animated.div>
        <animated.div style={bubble2} className="t-bubble tb2">
          <img src={tBubble2} alt="Thought Bubble" />
        </animated.div>
        <animated.div style={bubble3} className="t-bubble tb3">
          <img src={tBubble3} alt="Thought Bubble" />
        </animated.div>
        <animated.div style={bubble4} className="t-bubble tb4">
          <img src={tBubble4} alt="Thought Bubble" />
        </animated.div>
      </div>
      <animated.img
        style={puppyPeek}
        className="puppy-peeking"
        src={puppyPeeking}
        alt="Peeking Puppy"
      />
      <Waypoint onEnter={() => setOn(true)} />
    </Wrapper>
  );
};

export default PuppyThoughtBubble;
